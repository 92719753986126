<template>
  <v-card :loading="isLoading">
    <v-card-text v-if="clusterId">
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headers"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Kasa Hesap Hareketleri"
            icon="mdi mdi-safe"
            @clear-filters="clearFilters()"
            @click:edit="handleEditClick"
            @reload="loadList"
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
            :show-delete="can('delete-balance-activity')"
            :delete-enabled="deleteEnabled"
            @click:delete="handleBatchDeleteClick"
            v-bind="titleBarAttrs"
          >
            <template slot="buttons:prepend">
              <rs-select
                class="float-end me-2"
                style="max-width: 150px"
                label="Tarih Filtrele"
                :items="dateFilterList"
                @change="handleDateFilterChange"
                v-model="filterdate"
                dense
              />
            </template>
          </rs-table-title-bar>
          <v-row dense v-if="can('edit-bank-online')">
            <v-col>
              <v-btn
                class="me-1 mb-1"
                color="error"
                outlined
                dark
                small
                @click="transfer('outcome')"
              >
                Para Çekme
                <v-icon small>mdi-bank-transfer-out</v-icon>
              </v-btn>

              <v-btn
                class="me-1 mb-1"
                color="success"
                outlined
                dark
                small
                @click="transfer('income')"
              >
                Para Yatırma
                <v-icon small>mdi-bank-transfer-in</v-icon>
              </v-btn>

              <v-btn
                class="me-1 mb-1"
                color="primary"
                outlined
                dark
                small
                @click="transfer('safe')"
              >
                Kasalar Arası Transfer
                <v-icon small>mdi-safe</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <a href="#" @click.prevent="handleEditClick(item)">
            <i class="menu-icon mdi mdi-pencil"></i>
          </a>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.balance_no="{ item, value }">
          <a href="#" @click.prevent="handleBBEditClick(item)">
            <rs-table-cell-string :value="value" limit="20" />
          </a>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.bank_safe="{ item, value }">
          <router-link
            v-if="can('see-safe')"
            :to="{
              name: 'definitions.safes.show',
              params: { id: item.safe_id },
            }"
          >
            {{ value }}
          </router-link>
          <template v-else>{{ value }}</template>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.bank_safe_balance="{ value }">
          <rs-table-cell-balance colored :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.bank_safe_due="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.bank_safe_debt="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.action_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.description="{ value }">
          <rs-table-cell-string limit="20" :value="value" />
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>

    <TransferForm
      ref="transferForm"
      :type="transfertype"
      @saved="handleFormSaved"
    />

    <CollectingDetail ref="collectingDetailForm" />

    <DebtForm ref="debtForm" />

    <FirstBalanceForm ref="firstBalanceForm" @saved="loadList()" />

    <TransferDetail ref="transferDetail" />

    <rs-confirm
      ref="confirmDelete"
      :loading="isLoading"
      @confirmed="handleDeleteSubmitClick"
      @cancelled="$refs.confirmDelete.hide()"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions } from "@/view/mixins";

import { CollectingDetail, DebtForm } from "@/view/pages/income/forms";
import TransferForm from "../TransferForm";
import TransferDetail from "../TransferDetail";
import FirstBalanceForm from "@/view/content/safes/FirstBalanceForm";
import { isPage } from "../../../mixins";

export default {
  mixins: [filtersToURL, hasExport, hasPermissions, isPage],
  computed: {
    ...mapGetters(["clusterId"]),
    deleteEnabled() {
      if (this.selectedItems.length === 0) {
        return false;
      }

      for (const index in this.selectedItems) {
        if (this.selectedItems[index].type !== "transfer") {
          return false;
        }
      }

      return true;
    },
  },
  components: {
    CollectingDetail,
    DebtForm,
    TransferForm,
    TransferDetail,
    FirstBalanceForm,
  },
  data() {
    return {
      pageMeta: {
        title: "Kasa Hesap Hareketleri",
      },
      filterdate: 0,
      transfertype: null,
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "Sistem No",
          value: "balance_no",
          searchable: "text",
        },
        {
          text: "Belge No",
          value: "document_no",
          searchable: "text",
        },
        {
          text: "Kasa Kodu",
          value: "bank_safe",
          searchable: "text",
        },
        {
          text: "Tarih",
          value: "action_on",
          searchable: "date",
        },
        {
          text: "Fiş Türü",
          value: "bill_type",
          searchable: "text",
        },
        {
          text: "Borç Tutar",
          value: "bank_safe_debt",
          searchable: "number",
          align: "end",
        },
        {
          text: "Alacak Tutar",
          value: "bank_safe_due",
          searchable: "number",
          align: "end",
        },
        {
          text: "Bakiye",
          value: "bank_safe_balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "Açıklama",
          value: "description",
          searchable: "text",
        },
        {
          text: "Yıl",
          value: "year",
          searchable: "number",
        },
        {
          text: "Ay",
          value: "month",
          searchable: "number",
        },
      ],
      dateFilterList: [
        {
          id: 0,
          name: "Tümü",
        },
        {
          id: 1,
          name: "Bugün",
        },
        {
          id: 2,
          name: "Son 7 Gün",
        },
        {
          id: 3,
          name: "Son 30 Gün",
        },
        {
          id: 4,
          name: "Son 6 Ay",
        },
        {
          id: 5,
          name: "Son 1 Yıl",
        },
        {
          id: 6,
          name: "Son 2 Yıl",
        },
      ],
      options: {
        sortBy: ["order_num"],
        sortDesc: [true],
      },
      search: {
        action_on: {
          min: undefined,
          max: undefined,
        },
      },
      titleBarAttrs: {
        exportUrl: "balance-activities",
        exportParams: this.getParams,
      },
    };
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.scope_and = ["safe"];
      params.cluster_id = this.clusterId;

      return params;
    },
    clearFilters() {
      this.$refs.headSearch.reset();
      this.filterdate = null;
      this.search.query = "";
      this.search.action_on = {
        min: undefined,
        max: undefined,
      };
      this.loadList();
    },
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];

      const params = this.getParams();

      this.$api
        .query("balance-activities", { params })
        .then((response) => {
          const list = [];

          if (response.data && response.data.data) {
            for (const item of response.data.data) {
              list.push(item);
            }
          }

          this.list = list;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleBatchDeleteClick() {
      this.$refs.confirmDelete.show(
        "Seçilen transfer/transferleri silmek istediğinize emin misiniz?"
      );
    },
    handleDeleteSubmitClick() {
      if (this.isLoading) return false;

      this.isLoading = true;
      const promises = [];

      for (const index in this.selectedItems) {
        const promise = this.$api.delete(
          `income/transfers/${this.selectedItems[index].transfer_id}`
        );
        promises.push(promise);
      }

      Promise.all(promises)
        .then(() => {
          this.$toast.success("Seçilen transfer/transferler silindi");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.$refs.confirmDelete.hide();
          this.isLoading = false;
          this.loadList();
        });
    },
    loadSafeAccount(safeId, id) {
      this.$api
        .get("safes", safeId)
        .then((response) => {
          this.$refs.firstBalanceForm.show(
            id,
            {
              safe: response.data.data,
            },
            true
          );
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    handleEditClick(item) {
      if (!item.id) {
        item = this.selectedItems[0];
      }

      if (item.type === "collecting") {
        this.$router.push({
          name: "incomes.houses.show",
          params: { id: item.house_id },
        });
      } else if (item.type === "debt") {
        this.$refs.debtForm.show(item.debt_id, true);
      } else if (item.type === "invoice_payment" || item.type === "invoice") {
        this.$router.push({
          name: "expenses.invoices.edit",
          params: { id: item.invoice_id },
        });
      } else if (item.type === "transfer") {
        this.$refs.transferDetail.show(item.transfer_id);
      } else if (item.type === "openning") {
        this.loadSafeAccount(item.safe_id, item.id);
      } else if (item.type === "other_income") {
        this.$router.push({
          name: "incomes.other.edit",
          params: { id: item.other_income_id },
        });
      } else if (item.type === "other_income_payment") {
        this.$api
          .get(`income/payments/${item.other_income_payment_id}`)
          .then((response) => {
            this.$router.push({
              name: "incomes.other.edit",
              params: { id: response.data.data.other_income_id },
            });
          });
      }
    },
    handleBBEditClick(item) {
      if (!item.id) {
        return false;
      }
      if (item.enforcement_transaction_id) {
        this.$router.push({
          name: "enforcements.show",
          params: { id: item.enforcement_id },
        });
      } else if (item.type === "collecting") {
        this.$refs.collectingDetailForm.show(item.collecting_id, true);
      } else if (item.type === "debt") {
        this.$refs.debtForm.show(item.debt_id, true);
      } else if (item.type === "invoice_payment" || item.type === "invoice") {
        this.$router.push({
          name: "expenses.invoices.show",
          params: { id: item.invoice_id },
        });
      } else if (item.type === "transfer") {
        this.$refs.transferDetail.show(item.transfer_id);
      } else if (item.type === "openning") {
        this.loadSafeAccount(item.safe_id, item.id);
      } else if (item.type === "other_income") {
        this.$router.push({
          name: "incomes.other.show",
          params: { id: item.other_income_id },
        });
      } else if (item.type === "other_income_payment") {
        this.$api
          .get(`income/payments/${item.other_income_payment_id}`)
          .then((response) => {
            this.$router.push({
              name: "incomes.other.show",
              params: { id: response.data.data.other_income_id },
            });
          });
      }
    },
    handleFormSaved() {
      this.loadList();
    },
    transfer(type) {
      if (!type) {
        return false;
      }

      if (type === "safe") {
        this.transfertype = "safe";
        this.$refs.transferForm.show();
      } else if (type === "income") {
        this.transfertype = "income";
        this.$refs.transferForm.show();
      } else if (type === "outcome") {
        this.transfertype = "outcome";
        this.$refs.transferForm.show();
      }
    },
    handleDateFilterChange(value) {
      const t = new Date();

      if (this.search.action_on === undefined) {
        this.search.action_on = {
          min: undefined,
          max: undefined,
        };
      }

      if (value === null) {
        this.search.action_on = {
          min: undefined,
          max: undefined,
        };
      } else if (value === 1) {
        const todaystart = new Date();
        todaystart.setUTCHours(0, 0, 0, 0);
        const todayend = new Date();
        todayend.setUTCHours(23, 59, 59, 999);
        // payment in today
        this.search.action_on = {
          min: todaystart,
          max: todayend,
        };
      } else if (value === 2) {
        // payment in 7 days
        this.search.action_on = {
          min: new Date(t.getFullYear(), t.getMonth(), t.getDate() - 7),
          max: t,
        };
      } else if (value === 3) {
        // payment in 30 days
        this.search.action_on.min = new Date(
          t.getFullYear(),
          t.getMonth(),
          t.getDate() - 30
        );
        this.search.action_on.max = t;
      } else if (value === 4) {
        // payment in 6 months
        this.search.action_on.min = new Date(t.getFullYear(), t.getMonth() - 6);
        this.search.action_on.max = t;
      } else if (value === 5) {
        // payment in 1 year
        this.search.action_on.min = new Date(
          t.getFullYear(),
          t.getMonth() - 12
        );
        this.search.action_on.max = t;
      } else if (value === 6) {
        // payment in 2 year
        this.search.action_on.min = new Date(
          t.getFullYear(),
          t.getMonth() - 24
        );
        this.search.action_on.max = t;
      }

      this.loadList();
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
  },
};
</script>
